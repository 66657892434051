@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Alegreya+SC:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,500;1,700;1,800;1,900&family=Alegreya:ital,wght@0,400..900;1,400..900&family=Bebas+Neue&display=swap');

html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: relative;
  min-height: 100%;
  background-color: #ffffff;
  height: auto !important; 
}

.alegreya-sc-regular {
  font-family: "Alegreya SC", serif;
  font-weight: 400;
  font-style: normal;
}

.alegreya-sc-medium {
  font-family: "Alegreya SC", serif;
  font-weight: 500;
  font-style: normal;
}

.alegreya-sc-bold {
  font-family: "Alegreya SC", serif;
  font-weight: 700;
  font-style: normal;
}

.alegreya-sc-extrabold {
  font-family: "Alegreya SC", serif;
  font-weight: 800;
  font-style: normal;
}

.alegreya-sc-black {
  font-family: "Alegreya SC", serif;
  font-weight: 900;
  font-style: normal;
}

.alegreya-sc-regular-italic {
  font-family: "Alegreya SC", serif;
  font-weight: 400;
  font-style: italic;
}

.alegreya-sc-medium-italic {
  font-family: "Alegreya SC", serif;
  font-weight: 500;
  font-style: italic;
}

.alegreya-sc-bold-italic {
  font-family: "Alegreya SC", serif;
  font-weight: 700;
  font-style: italic;
}

.alegreya-sc-extrabold-italic {
  font-family: "Alegreya SC", serif;
  font-weight: 800;
  font-style: italic;
}

.alegreya-sc-black-italic {
  font-family: "Alegreya SC", serif;
  font-weight: 900;
  font-style: italic;
}


.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

